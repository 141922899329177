@import './../../../../scss/theme-bootstrap';

// Definitions
// - Variables
// - Reusable Classes .wf-* (waitlist form) specific styling used in multiple places along this file
$notify-me-button: 120px;

.wf-underline-style {
  text-decoration: underline;
  text-underline-position: under;
  text-underline-offset: 2px;
}

.wf-width-100-flex {
  display: flex;
  width: 100%;
}

// Overriding some existing styling
// This was needed due to the same classes usage in different cases
// In this way I'm just adding this style when needed - with this single file
.product-full {
  &__form {
    order: 16;
  }

  &__cta-button-container {
    &:not(.hidden) + .waitlist-form {
      display: none;
    }

    &.hidden + .waitlist-form {
      display: block;
      width: 100%;
    }
  }
}

.waitlist-form {
  @extend .wf-width-100-flex;
  align-items: stretch;
  background-color: unset;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  // Flex display elements
  & .flex-col {
    @extend .wf-width-100-flex;
    flex-direction: column;
  }
  & .flex-row {
    @extend .wf-width-100-flex;
    flex-direction: row;
  }
  // Form elements
  &__container {
    width: 100%;
  }
  &__form {
    @extend .wf-width-100-flex;
    margin: 0;
  }
  &__button {
    height: 100%;
    min-width: $notify-me-button;
    margin: auto;
    padding: 0 25px;
  }
  &__email {
    margin: 0;
  }
  // Form Feedback messages
  &__errors {
    font-size: 14px;
    margin-top: 8px;
    margin-bottom: 0;
    text-align: center;

    @include breakpoint($medium-up) {
      text-align: #{$ldirection};
    }

    &-quickshop {
      text-align: center;
      margin: 1em 0 0;
    }

    & h1 {
      font-size: 14px;
    }
  }
  &__success {
    &__header {
      background: $color-black;
      color: $color-white;
      margin-bottom: 8px;
      padding: 20px;
      width: 100%;
    }
    &__message {
      color: $color-success;
      font-size: 14px;
      line-height: 1;
      margin-bottom: 0;
    }
  }
  // GDPR - How do we use your data?
  // Some extra style for the way the bottom line looks
  &__data-usage {
    @extend .wf-underline-style;
  }
  // specify element type because there are other definitions that are have higher priority
  // this way i'm not gonna alter any other styling from other definitions
  input.waitlist-form__email {
    font-size: 16px;
    height: 100%;
    margin-#{$rdirection}: auto;
    min-height: 100%;
    padding: 0 1rem;
    width: calc(
      calc(100% - #{$notify-me-button}) - 15px
    ); // extract the notify me button width and requested margin too.
    &::placeholder {
      font-size: 14px;
    }
  }
}

.waitlist-form,
.waitlist-form__full {
  .site-email-signup {
    &__gdpr-link {
      @extend .wf-underline-style;
      cursor: pointer;
      color: unset;
      margin-bottom: 0;
      position: relative;
      .product-full__social-fav & {
        position: unset;
      }
      text-align: center;
      @include breakpoint($medium-up) {
        text-align: #{$rdirection};
        top: 55px;
      }
      &:hover > .site-email-signup__gdpr-overlay {
        display: block;
      }
    }
    &__gdpr-overlay {
      background-color: $color-white;
      border: 1px solid $color-pink-accent;
      bottom: 20px;
      color: $color-black;
      display: none;
      padding: 15px;
      position: absolute;
      text-decoration: none;
      width: 100%;
      .product-full__social-fav & {
        bottom: 50px;
        #{$ldirection}: 0;
      }
      p {
        margin: 0;
      }
    }
  }
}
